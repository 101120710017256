import {
  Box,
  Button,
  Card,
  Divider,
  Label,
  Paper,
  Textfield,
} from "@mui/material"

import React from "react"

export default function About({ children }) {
  return (
      <Paper>{children}</Paper>
  )
}
