import "../../assets/styles/global/global.css"

import { Link } from "gatsby"
import React from "react"

export default function Nav() {
  const styles = {
  
  }

  return (
    <nav>
      <h1 className="siteName">Rian Gray</h1>
      <div className="linkBox">
        <Link to="/">
          Home
        </Link>
        <Link to="/projects">
          Projects
        </Link>
        <Link to="/">
          Contact
        </Link>
      </div>
    </nav>
  )
}
