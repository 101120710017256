import * as heroStyles from "../../assets/styles/component/hero.module.css"

import { Box } from "@mui/material"
import React from "react"

export default function Hero({ children }) {
  const styles = {
    heroSection: {
      color: "#dcf3f0",
      borderColor: "#ee6c4d",
      padding: "5px",
      margin: "3px",
    },
  }

  return (
  <Box className={heroStyles.header}>
    {children}
  </Box>)
}
