import {
  Box,
  Button,
  Card,
  Divider,
  Label,
  Paper,
  Textfield,
} from "@mui/material"

import React from 'react'

export default function Projects({children}) {
  const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridGap: "40px",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "0px",
      padding: "20px",
      verticalAlign: "top"
    },
  } 
  return (
    <Paper styles={styles.container}>      
      {children}
    </Paper>
  )
}
