import { GatsbyImage, getImage } from "gatsby-plugin-image"

import About from "../components/sections/About"
import Frameworks from "../components/sections/Frameworks"
import Hero from "../components/sections/Hero"
import Languages from "../components/sections/Languages"
import Layout from "../components/layout/Layout"
import Methodologies from "../components/sections/Methodologies"
import Projects from "../components/sections/Projects"
import React from "react"
import SummaryCard from "../components/sections/SummaryCard"
import Tools from "../components/sections/Tools"

export default function Home() {
  return (
    <Layout>
      <Hero>
        <div>
          <p className="heroSubHeading">Portfolio</p>
          <p className="heroText">
            Under Construction
          </p>
        </div>
        <div>
          <p></p>
        </div>
      </Hero>
      <About>
        <p className="highlightHeading">About</p>

      </About>
      <Projects>
        <p className="highlightHeading">Projects</p>
        <p></p>
      </Projects>
      <Languages>
        <p className="highlightHeading">Languages</p>
      </Languages>
      <Frameworks>
        <p className="highlightHeading">Frameworks</p>
      </Frameworks>
      <Tools>
        <p className="highlightHeading">Tools</p>
      </Tools>
      <Methodologies>
        <p className="highlightHeading">Methodologies</p>
      </Methodologies>
    </Layout>
  )
}
