import {
  Box,
  Button,
  Card,
  Divider,
  Label,
  Paper,
  Textfield,
} from "@mui/material"

import React from 'react'

export default function Methodologies({children}) {
  return (
    <Paper>
      
      {children}
    </Paper>
  )
}
