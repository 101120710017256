import '../../assets/styles/global/global.css'

import Content from './Content'
import Footer from './Footer'
import Header from './Header'
import React from 'react'

export default function Layout({children}) {
  return (
    <div className='layout'>
        <Header />
        <Content>
            {children}
        </Content>
        <Footer />   
    </div>
  )
}
